/* Dashboard container styling */
.dashboard-container {
    padding: 20px;
    width: 95%;
    max-width: 1600px;
    margin: 0 auto;
    box-sizing: border-box;
}

/* Responsive typography */
h2 {
    text-align: left;
    color: #333;
    font-family: SpaceMono, SpaceGrotesk, InknutAntiqua, Arial, sans-serif;
    font-size: 2.4em;
    margin-bottom: 20px;
}

/* Filters container */
.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
}

.filters label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.filters select {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    min-width: 80px;
}

/* Range Slider Container */
.range-slider-container {
    position: relative;
    width: 100%;
    height: 10px;
}

.mileage-filter {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 200px;
}

.mileage-inputs {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

/* Common styles for both sliders */
.mileage-slider {
    position: absolute;
    width: 100%;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    height: 6px;
    opacity: 0;
}

/* Slider Track */
.slider-track {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    border-radius: 3px;
    background: #ddd;
}

/* Active range highlight */
.slider-track::before {
    content: '';
    position: absolute;
    left: var(--left-percent, 0%);
    right: var(--right-percent, 0%);
    height: 100%;
    background: #ddd;
    border-radius: 3px;
}

/* Thumb styles */
.mileage-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    cursor: pointer;
    pointer-events: auto;
    margin-top: -5px;
}

.mileage-slider::-moz-range-thumb {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #ddd;
    cursor: pointer;
    pointer-events: auto;
    border: none;
}

/* Display values */
.mileage-values {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 0.9rem;
    color: #666;
}

/* Make both sliders functional */
.min-slider {
    z-index: 3;
}

.max-slider {
    z-index: 4;
}

/* Active state */
.mileage-slider:active::-webkit-slider-thumb {
    transform: scale(1.2);
}

.mileage-slider:active::-moz-range-thumb {
    transform: scale(1.2);
}

/* Update existing filters class to better accommodate the slider */
.filters {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
    align-items: start;
}

/* Table container styling */
.dashboard-table-container {
    width: 100%;
    overflow-x: auto;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 0 auto;
}

.car-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: white;
}

.car-table th,
.car-table td {
    padding: 12px 10px;
    white-space: nowrap;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    transition: background-color 0.3s ease;
    text-overflow: ellipsis;
    overflow: hidden;
}

.car-table th {
    background-color: #f8f9fa;
    color: #333;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
    text-transform: uppercase;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
}

.car-table th:hover {
    background-color: #f1f3f5;
    cursor: pointer;
}

.car-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Car URL link styling (first column) */
.car-table td:first-child a {
    color: #323232;
    text-decoration: underline;
    transition: color 0.2s ease;
}

.car-table td:first-child a:hover {
    color: #333;
}

/* Carfax View Report link styling (remains white due to button background) */
.view-analysis-button {
    padding: 6px 12px;
    background-color: #4CAF50;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
    display: inline-block;
}

.view-analysis-button:hover {
    background-color: #45a049;
}

/* Animation keyframes */
@keyframes ellipsis {
    0% {
        content: '.';
    }

    33% {
        content: '..';
    }

    66% {
        content: '...';
    }

    100% {
        content: '.';
    }
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.6;
    }
}

@keyframes gradient {
    0% {
        background-position: 100% 0;
    }

    100% {
        background-position: -100% 0;
    }
}

/* Checking status styling */
.availability-checking {
    display: inline-flex;
    align-items: center;
    color: #383838;
    font-style: italic;
    animation: pulse 2s infinite ease-in-out;
}

.availability-checking::after {
    content: '.';
    width: 1em;
    text-align: left;
    animation: ellipsis 1.5s infinite;
}

/* Row styling for checking status */
.car-table tr[data-availability="checking"] {
    background: linear-gradient(90deg,
            #fafafa 25%,
            #f5f5f5 50%,
            #fafafa 100%);
    background-size: 200% 100%;
    animation: gradient 2s ease infinite;
}

.car-table tr[data-availability="checking"] td {
    color: #666;
}

/* Availability states styling */
.car-table tr[data-availability="Not Available"] {
    background-color: #f8f8f8;
    opacity: 0.85;
}

.car-table tr[data-availability="Not Available"] td {
    color: #666;
}

.car-table tr[data-availability="Not Available"] img {
    filter: grayscale(100%);
    opacity: 0.6;
}

.car-table tr:hover {
    background-color: #f1f1f1;
}

/* Availability cell styling */
.car-table td.availability-cell {
    font-weight: 500;
    transition: color 0.3s ease;
}

/*.car-table td.availability-cell[data-status="Available"] {
    color: #28a745;
}
*/
.car-table td.availability-cell[data-status="Not Available"] {
    color: #dc3545;
}

/* Responsive image handling */
.car-table img {
    max-width: 100px;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
}

/* Default styling for Condition Score */
.condition-score {
    display: inline-block;
    padding: 8px 20px;
    /* Increase padding */
    font-weight: 500;
    border-radius: 6px;
    /* Slightly larger rounded corners */
    color: white;
    text-align: center;
    min-width: 80px;
    /* Increase the minimum width */
}

/* Color ranges */
.condition-score.red {
    background-color: #dc3545;
    /* Red */
}

.condition-score.orange {
    background-color: #fd7e14;
    /* Orange */
}

.condition-score.light-green {
    background-color: #28a745;
    /* Light Green */
}

.condition-score.dark-green {
    background-color: #17732b;
    /* Dark Green */
}

/* New Gray Color for Missing Scores */
.condition-score.gray {
    background-color: #adb5bd;
    /* Light Gray */
    color: black;
}


/* Delete button styling */
.delete-button {
    /*    background-color: #dc3545;
color: white;
*/
    color: #dc3545;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #c82333;
    color: white;
}

/* VIN debugging cell styling */
.debug-vin-cell {
    text-align: center;
}

.view-analysis-button {
    padding: 6px 12px;
    background-color: #2aa958;
    color: #ffffff;
    text-decoration: none;
    border-radius: 4px;
    font-size: 0.9rem;
}

.view-analysis-button:hover {
    background-color: #5dda8b;
    color: #ffffff;
}

.no-report {
    font-size: 0.9rem;
}

.no-report-text {
    color: #666;
}

.car-table td:first-child {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media screen and (max-width: 1400px) {
    .dashboard-container {
        width: 98%;
        padding: 10px;
    }

    .car-table th,
    .car-table td {
        padding: 8px 6px;
        font-size: 0.9rem;
    }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
    }

    .filters {
        flex-direction: column;
    }

    .filters label {
        width: 30%;
    }

    /* Optional: Make table more compact on smaller screens */
    .car-table {
        font-size: 0.9rem;
    }

    .car-table th,
    .car-table td {
        padding: 8px 10px;
    }
}

/* Sorting indicator */
.sort-indicator {
    margin-left: 5px;
    font-size: 0.8rem;
    opacity: 0.7;
}