.carousel {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 30px;
    position: relative;
}

.carousel-track {
    display: flex;
    gap: 120px;
    animation: scroll 30s linear infinite;
}


.carousel-logo {
    width: 100px;
    /* Set a maximum width */
    height: auto;
    /* Automatically adjust the height to maintain aspect ratio */
    object-fit: contain;
    /* Ensure the image fits within the given dimensions without distortion */
    flex-shrink: 0;
    /* Prevent the logos from shrinking */
}

@keyframes scroll {
    0% {
        transform: translateX(90);
    }

    100% {
        transform: translateX(-30%);
    }
}