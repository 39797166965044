/* Define font */


@font-face {
  font-family: 'SpaceGrotesk';
  src: url('assets/fonts/spacegrotesk/SpaceGrotesk-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'InknutAntiqua';
  src: url('assets/fonts/inknutantiqua/InknutAntiqua-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('assets/fonts/syne/Syne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('assets/fonts/spacemono/SpaceMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #ffffff;
  /* Set the entire page background to white */
  margin: 0;
  /* Remove default margin */
  padding: 0;
  /* Remove default padding */
  font-family: 'Arial', sans-serif;
  /* Set a default font */
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
}

.home-header {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-logo {
  width: 100px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.home-logo:hover {
  transform: scale(1.1);
}

h1 {
  font-family: 'SpaceMono', 'SpaceGrotesk', 'InknutAntiqua', 'Arial', sans-serif;
  font-size: 3.5rem;
  margin-bottom: 5px;
  color: #333;
}

.home-header h1 {
  font-size: 5.5rem;
  margin-top: 5px;
  margin-bottom: 15px;
  color: #333;
}

.home-description {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-description h2 {
  font-family: 'SpaceMono', 'SpaceGrotesk', 'InknutAntiqua', 'Arial', sans-serif;
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 0px;
  text-align: center;
}

.home-description h3 {
  font-family: 'SpaceGrotesk', 'InknutAntiqua', 'Arial', sans-serif;
  font-size: 1.7rem;
  margin-top: 35px;
  margin-bottom: 15px;
}

.slogan {
  font-family: 'Arial', sans-serif;
  max-width: 750px;
  /* Limit the width to make the text narrower */
  margin: 20px auto;
  /* Center the text with automatic margins */
  font-size: 1.1rem;
  /* Adjust font size for emphasis */
  text-align: center;
  /* Center the text */
  line-height: 1.5;
  /* Add some line height for readability */
  color: #333;
  /* Ensure consistent color */
}

.car-tracker-summary {
  font-family: 'Arial', 'SpaceGrotesk', 'InknutAntiqua', 'Arial', sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  margin-top: 10px;
  text-align: center;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.form-section {
  max-width: 400px;
  margin: 20px auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container {
  display: flex;
  flex-direction: column;
}

.welcome-back {
  text-align: center;
}

.switch-to-signup p {
  margin-top: 20px;
  font-size: 1rem;
  color: #333;
}

.switch-to-signup a {
  color: #007bff;
  text-decoration: none;
}

.switch-to-signup a:hover {
  text-decoration: underline;
}

.home-footer {
  width: 100%;
  max-width: 1200px;
  margin: auto auto 0 auto;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #ccc;
  box-sizing: border-box;
}

.home-footer p {
  margin: 0;
  font-size: 0.9rem;
}

.features-container {
  width: 100%;
  max-width: 1200px;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.feature-box {
  /*  background-color: #f9f9f9; /* Slightly different shade for feature boxes */
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  position: relative;
  width: 225px;
  /* Set a fixed width of approximately 225px */
  box-sizing: border-box;
  /* Include padding in width */
  margin-bottom: 0px;
  /* Add margin at the bottom for spacing */
  flex: 0 0 auto;
  /* Prevents flex items from stretching or shrinking */
}

.feature-box strong {
  font-size: .95rem;
  /* Smaller font size for the feature title */
  display: block;
  /* Make it a block element for better spacing */
  margin-bottom: 5px;
  /* Add some space below the title */
}

.feature-box p {
  font-size: .95rem;
  /* Even smaller font size for the feature description */
  line-height: 1.4;
  /* Adjust line height for better readability */
  margin-top: 0;
  /* Remove top margin to tighten up the layout */
}

@media (max-width: 768px) {
  .features-container {
    overflow-x: scroll;
    /* Enable horizontal scroll on smaller screens */
  }

  .feature-box {
    width: 200px;
    /* Reduce width slightly for better fit on small screens */
  }

  .car-tracker-summary {
    width: 100%;
    max-width: 850px;
    margin: 40px auto;
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .home {
    padding: 10px;
  }

  .home-header h1 {
    font-size: 3rem;
    /* Reduce font size for mobile */
  }

  h1 {
    font-size: 2.5rem;
    /* Reduce font size for mobile */
  }

  .home-description {
    font-size: 1rem;
    /* Slightly smaller font size for mobile */
  }

  .home-description h2 {
    font-size: 1.3rem;
    /* Adjust h2 size for mobile */
  }

  .home-description h3 {
    font-size: 1.5rem;
    /* Adjust h3 size for mobile */
    margin-top: 30px;
    /* Reduce top margin for mobile */
  }

  .features-container {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  .feature-box {
    width: 100%;
    max-width: 300px;
  }

  .feature-box strong {
    font-size: 0.95rem;
    /* Adjust size for mobile if needed */
  }

  .feature-box p {
    font-size: 0.85rem;
    /* Adjust size for mobile if needed */
  }

  .extension-section {
    margin: 20px auto;
    padding: 20px;
  }

  .form-section {
    padding: 20px;
    max-width: 100%;
    /* Full width on mobile */
  }

  .slogan {
    font-size: 1rem;
    /* Smaller font size for mobile */
    padding: 0 10px;
    /* Add some padding */
  }

  .car-tracker-summary {
    padding: 0 20px;
  }
}

/* Tablet Responsive Styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .home-header h1 {
    font-size: 4rem;
    /* Adjust font size for tablets */
  }

  .features-container {
    justify-content: center;
    /* Center features on tablets */
    flex-wrap: wrap;
    /* Allow wrapping on tablets */
  }

  .feature-box {
    width: calc(50% - 20px);
    /* Two columns on tablets */
    max-width: none;
  }

  .feature-box strong {
    font-size: 1rem;
    /* Slightly larger font for tablets */
  }

  .feature-box p {
    font-size: 0.9rem;
    /* Slightly larger font for tablets */
  }
}


.color-bar {
  height: 4px;
  width: 70%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  /* Center the bar */
}

.color-bar-1 {
  background-color: #33ff57;
}

.color-bar-2 {
  background-color: #33c1ff;
}

.color-bar-3 {
  background-color: #ffc133;
}

.color-bar-4 {
  background-color: #ff5733;
}

.color-bar-5 {
  background-color: #00ddb3;
}