/* src/Navbar.css */

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('assets/fonts/spacegrotesk/SpaceGrotesk-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'InknutAntiqua';
  src: url('assets/fonts/inknutantiqua/InknutAntiqua-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Syne';
  src: url('assets/fonts/syne/Syne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('assets/fonts/spacemono/SpaceMono-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2b2b2b;
  padding: 1rem;
}

.navbar-brand a {
  color: #fff;
  font-family: 'SpaceMono', 'SpaceGrotesk', 'InknutAntiqua', 'Arial', sans-serif;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.navbar-logo {
  height: 30px;
  width: auto;
}

/* Remove .navbar-links if there are no additional links to display */
.navbar-links {
  display: none;
  /* Hide navbar links if "Dashboard" should be only in the dropdown */
}

.navbar-user-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar-signup-button {
  background-color: #FFA500;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-signup-button:hover {
  background-color: #e69500;
  /* Slightly darker shade on hover */
}

.navbar-user-icon {
  margin-left: auto;
  /* Ensure the user icon is aligned to the right */
  position: relative;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.user-icon {
  width: 20px;
  cursor: pointer;
  margin-right: 15px;
}

.dropdown-content {
  display: none;
  /* Hidden by default */
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  /* Align dropdown to the right */
  text-align: left;
}

.dropdown-content a,
.dropdown-content button {
  color: #343a40;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: white;
  border: none;
  text-align: left;
  width: 100%;
  /* Ensure full width */
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
  /* Show dropdown on hover */
}

.navbar-auth-button,
.navbar-logout-button {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  /* Adjust padding for consistency */
  font-size: 1rem;
  color: #343a40;
}

/* Ensure hover effects apply to all dropdown items */
.navbar-auth-button:hover,
.navbar-logout-button:hover {
  background-color: #ddd;
}

.dropdown-content.show {
  display: block;
}

.navbar-extension-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #828486;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 20px;
}

.navbar-extension-button:hover {
  background-color: #2957bc;
}

.chrome-icon-nav {
  width: 16px;
  height: 16px;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem;
  }

  .navbar-brand a {
    font-family: 'SpaceMono', 'SpaceGrotesk', 'InknutAntiqua', 'Arial', sans-serif;
    font-size: 1.2rem;
  }

  .navbar-user-actions {
    gap: 0.5rem;
  }

  .navbar-signup-button {
    padding: 6px 10px;
    font-size: 0.8rem;
    white-space: nowrap;
  }

  .user-icon {
    width: 18px;
    height: 18px;
  }

  .dropdown-content {
    position: absolute;
    top: 100%;
    right: 0;
    width: auto;
    min-width: 120px;
  }

  .navbar-extension-button {
    padding: 6px 10px;
    font-size: 0.8rem;
    margin-right: 12px;
  }

  .chrome-icon-nav {
    width: 14px;
    height: 14px;
  }

}

/* For very small screens */
@media (max-width: 360px) {
  .navbar-brand a {
    font-size: 1rem;
  }

  .navbar-signup-button {
    padding: 4px 8px;
    font-size: 0.7rem;
  }

  .user-icon {
    width: 16px;
    height: 16px;
  }

  .navbar-extension-button {
    padding: 4px 8px;
    font-size: 0.7rem;
    margin-right: 8px;
  }

  .chrome-icon-nav {
    width: 12px;
    height: 12px;
  }
}