/* responsive.css */

/* Small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  body {
    font-size: 16px; /* Base font size for better readability on mobile */
  }

  .home, .form-section {
    width: 100%;
    max-width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }

  .home > * {
    margin-bottom: 20px; /* Add space between major sections */
  }

  .home-header {
    margin-bottom: 30px;
  }

  .home-logo {
    width: 80px;
  }

  .home-header h1 {
    font-size: 1.8rem; /* Slightly smaller than current 2rem */
  }

  .home-description {
    font-size: 1rem;
  }

  .home-description h2 {
    font-size: 1.2rem;
    margin-top: 20px;
  }

  .home-description h3 {
    font-size: 1.4rem;
    margin-top: 40px;
  }

  .feature-list {
    padding-left: 20px;
  }

  .form-section {
    padding: 20px;
  }

  .features-carousel {
    display: block; /* Show carousel */
    width: 100%;
  }

  .feature-box {
    width: 100%;
    margin-bottom: 15px;
  }

  /* Form and button improvements */
  .form-section input,
  .form-section button,
  .navbar-signup-button {
    min-height: 44px; /* Minimum touch target size */
    font-size: 16px; /* Prevent iOS from zooming on input focus */
  }

  /* Navbar adjustments */
  .navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .navbar-brand, .navbar-user-actions {
    width: 100%;
    text-align: center;
  }

  .navbar-user-actions {
    justify-content: space-between;
    margin-top: 10px;
  }

  .navbar-signup-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .dropdown-content {
    width: 100%;
    position: static;
    box-shadow: none;
  }
}

/* Medium devices (tablets, 768px and up) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .home-header h1 {
    font-size: 2.5rem;
  }

  .home-description {
    font-size: 1.1rem;
  }

  .features-carousel .slick-slide {
    padding: 0 5px;
  }

  .feature-box {
    padding: 15px;
  }

  /* Navbar adjustments */
  .navbar-user-actions {
    gap: 0.5rem;
  }
}

/* Larger devices (small laptops, 992px and up) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .features-carousel {
    width: 90%;
  }

  .feature-box {
    padding: 20px;
  }
}

/* General responsive adjustments */
@media only screen and (max-width: 992px) {
  .home-description {
    max-width: 100%;
  }

  .form-section {
    max-width: 90%;
  }
}