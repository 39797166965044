/* Define the SpaceMono font */
@font-face {
    font-family: 'SpaceMono';
    src: url('components/assets/fonts/spacemono/SpaceMono-Regular.ttf') format('truetype'); /* Adjust the path according to your folder structure */
    font-weight: normal;
    font-style: normal;
}

/* Apply the SpaceMono font to the entire body */
body {
    margin: 0;
    font-family: 'SpaceMono', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Apply a monospace font for code elements */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
