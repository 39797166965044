.contact-form {
    background: linear-gradient(to bottom right, #f8fafc, #f1f5f9);
}

.contact-input {
    @apply w-full px-4 py-3 border border-gray-300 rounded-lg transition-all duration-200;
}

.contact-input:focus {
    @apply ring-2 ring-blue-500 border-transparent outline-none;
}

.contact-button {
    @apply w-full bg-blue-600 text-white py-3 px-4 rounded-lg font-medium transition-colors duration-200;
}

.contact-button:hover:not(:disabled) {
    @apply bg-blue-700;
}

.contact-button:disabled {
    @apply bg-gray-400 cursor-not-allowed;
}

.spinner {
    @apply animate-spin h-5 w-5;
}

.status-message {
    @apply flex items-center gap-3 p-4 rounded-lg;
}

.status-message.success {
    @apply bg-green-50 text-green-800 border border-green-200;
}

.status-message.error {
    @apply bg-red-50 text-red-800 border border-red-200;
}