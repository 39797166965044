.extension-section {
    width: 100%;
    max-width: 1200px;
    margin: 40px auto;
    margin-top: 20px;
    padding: 20px 20px;
    background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
    border-radius: 12px;
    box-sizing: border-box;
}

.extension-content {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.extension-content h2 {
    font-family: 'Arial', sans-serif, 'SpaceMono', 'SpaceGrotesk';
    font-size: 1.4rem;
    color: #333333;
    margin-bottom: 10px;
}

.extension-features {
    display: flex;
    justify-content: space-around;
    gap: 30px;
    margin: 40px 0;
}

.extension-feature {
    flex: 1;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease;
}

.extension-feature:hover {
    transform: translateY(-5px);
}

.feature-icon {
    font-size: 2rem;
    display: block;
    margin-bottom: 15px;
}

.extension-feature p {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.4;
}

.install-container {
    margin-top: 10px;
}

.install-button {
    background-color: #2b2b2b;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 1.2rem;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.install-button:hover {
    background-color: #1b77d9;
}

.install-steps {
    margin-top: 20px;
    font-size: 1rem;
    color: #666;
}

@media (max-width: 768px) {
    .extension-section {
        padding: 40px 15px;
        margin: 20px 0;
    }

    .extension-content h2 {
        font-size: 1.8rem;
    }

    .extension-features {
        flex-direction: column;
        gap: 20px;
    }

    .extension-feature {
        padding: 15px;
    }

    .install-button {
        padding: 12px 25px;
        font-size: 1.1rem;
    }
}

.chrome-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    vertical-align: middle;
}