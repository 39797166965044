.carfax-analysis-container {
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}

.carfax-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}

.carfax-header h2 {
    margin: 0;
    color: #333;
    font-family: SpaceMono, SpaceGrotesk, InknutAntiqua, Arial, sans-serif;
    font-size: 2.4em;
}

.back-button {
    padding: 8px 16px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.back-button:hover {
    background-color: #e0e0e0;
}

.analysis-content {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.metrics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.metric-card {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.metric-value {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
}

.metric-label {
    color: #666;
    font-size: 0.9rem;
}

.score-bar {
    height: 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    margin-top: 10px;
    overflow: hidden;
}

.score-fill {
    height: 100%;
    border-radius: 4px;
    transition: width 0.3s ease;
}

.analysis-section {
    margin-bottom: 30px;
}

.analysis-section h3 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 15px;
    padding-bottom: 8px;
    border-bottom: 2px solid #f0f0f0;
}

.service-record {
    background-color: #f8f9fa;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 6px;
}

.service-date {
    color: #666;
    font-size: 0.9rem;
}

.service-description {
    margin-top: 5px;
    color: #333;
}

.location-history {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 20px;
}

.rust-belt-warning {
    color: #dc3545;
    margin-top: 10px;
    font-size: 0.9rem;
}

.major-issues-list {
    list-style: none;
    padding: 0;
}

.major-issue-item {
    background-color: #f8f9fa;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 6px;
    border-left: 4px solid #dc3545;
}

.error-message {
    color: #dc3545;
    padding: 1rem;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .carfax-analysis-container {
        padding: 10px;
    }

    .carfax-header h2 {
        font-size: 1.8em;
    }

    .metrics-grid {
        grid-template-columns: 1fr;
    }

    .metric-card {
        padding: 15px;
    }
}